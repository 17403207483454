import Keycloak from 'keycloak-js';
import { AppUser } from '../types/user';

const environment = process.env.REACT_APP_ENV;
const kcClient = new Keycloak(`/keycloak-${environment}.json`);

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
  kcClient.init({
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
    pkceMethod: 'S256',
  })
    .then((authenticated) => {
      if (!authenticated) {
        console.log('user is not authenticated..!');
      }
      onAuthenticatedCallback();
    })
    .catch(console.error);
};

const doLogin = kcClient.login;

const doLogout = kcClient.logout;

const getToken = () => kcClient.token;

const isLoggedIn = () => !!kcClient.token;

const updateToken = (successCallback) =>
  kcClient.updateToken(5)
    .then(successCallback)
    .catch(doLogin);

kcClient.onTokenExpired = () => {
  console.log('token expired');
  kcClient.updateToken(5).then(() => {
    console.log('successfully got a new token');
  }).catch(doLogin);
};

const getRolesArray = () => {
  const token = kcClient.tokenParsed;
  const realmRoles = token?.realm_access?.roles ?? [];
  const resourceAccess = token?.resource_access?.[kcClient.clientId];
  const resourceRoles = resourceAccess?.roles ?? [];
  return [...realmRoles, ...resourceRoles];
};

const getUserData = (): AppUser => {
  return {
    id: kcClient.tokenParsed?.sub,
    lastName: kcClient.tokenParsed?.family_name,
    firstName: kcClient.tokenParsed?.given_name,
    emailVerified: kcClient.tokenParsed?.email_verified,
    email: kcClient.tokenParsed?.email,
    name: kcClient.tokenParsed?.name,
    username: kcClient.tokenParsed?.preferred_username,
    roles: getRolesArray(),
    partnerId: '',
    avatar: ''
  };
};

const getUsername = () => kcClient.tokenParsed?.preferred_username;
const getEmail = () => kcClient.tokenParsed?.email;
const getAvatar = () => kcClient.tokenParsed?.avatar;
const getUserId = () => kcClient.tokenParsed?.sub;
const getRoles = () => ({ resource: kcClient.tokenParsed?.resource_access, realm: kcClient.tokenParsed?.realm_access });


const hasRealmRole = (roles) => roles.some((role) => kcClient.hasRealmRole(role));
const hasRole = (roles) => roles.some((role) => kcClient.hasResourceRole(role, 'pay-api'));

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
  hasRealmRole,
  getUserId,
  getEmail,
  getAvatar,
  getRoles,
  getUserData
};

export default UserService;
