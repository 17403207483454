import { createSvgIcon } from '@mui/material/utils';

const LumenLogo = createSvgIcon(
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 500 500"
        width="100%"
        fill="currentColor"
    >
        <path fill="#1E5AE3"
              d="
M499.000244,205.000000 
	C498.992371,270.660370 499.201599,335.821716 498.900635,400.980743 
	C498.697876,444.883057 467.395782,484.734283 424.938385,495.421356 
	C416.153717,497.632538 406.871796,498.829285 397.815094,498.851227 
	C299.491760,499.089630 201.167664,499.000916 102.843765,498.999878 
	C52.273956,498.999359 9.796882,461.355499 3.865472,411.183594 
	C3.280696,406.237183 3.037097,401.222473 3.034700,396.239258 
	C2.987558,298.248688 2.994948,200.258102 3.001649,102.267509 
	C3.004845,55.534946 36.682068,14.280579 82.519859,5.224168 
	C89.654633,3.814513 97.046127,3.101404 104.320335,3.091298 
	C202.644043,2.954701 300.968018,2.997769 399.291901,3.000375 
	C450.096954,3.001722 492.913727,41.544434 498.137726,92.056122 
	C498.667847,97.182190 498.951416,102.356743 498.963104,107.509682 
	C499.036438,139.839661 499.000244,172.169861 499.000244,205.000000 
M468.997650,236.500000 
	C468.997620,192.836105 469.053619,149.172043 468.930359,105.508492 
	C468.914001,99.715286 468.632935,93.779770 467.373596,88.156693 
	C460.001282,55.238075 432.479645,33.203823 398.721252,33.030231 
	C372.890320,32.897404 347.058136,33.002983 321.226532,33.002522 
	C319.643921,33.002491 318.061340,33.002514 316.532410,33.002514 
	C316.502136,33.694290 316.430267,33.894436 316.499115,33.976437 
	C317.783752,35.506401 319.070679,37.034946 320.385132,38.539268 
	C335.901947,56.297169 345.335541,76.818741 346.094452,100.457146 
	C346.981659,128.091324 346.877655,155.762085 346.923340,183.417450 
	C347.000702,230.245377 346.855347,277.073669 346.820709,323.901825 
	C346.819641,325.337555 347.008057,326.773438 347.105530,328.171753 
	C365.407349,328.171753 383.258453,328.823303 401.045258,328.031311 
	C434.439819,326.544312 460.849243,304.349243 467.281128,271.833862 
	C469.481293,260.711273 468.516449,248.962616 468.997650,236.500000 
M33.002365,278.500000 
	C33.002377,317.982605 32.936413,357.465424 33.074989,396.947540 
	C33.095295,402.733215 33.465248,408.656311 34.734707,414.276093 
	C42.017750,446.517395 69.633522,468.736237 102.672401,468.954712 
	C131.491882,469.145325 160.313599,469.000488 189.134308,468.980469 
	C190.132721,468.979797 191.130966,468.727783 192.452652,468.549225 
	C172.848022,449.672455 160.061661,427.579987 157.025787,400.952393 
	C155.222397,385.134949 155.204697,369.064056 155.160812,353.105011 
	C155.013321,299.464844 155.214386,245.823746 155.281662,192.182999 
	C155.284088,190.250046 155.281982,188.317093 155.281982,186.065491 
	C137.215485,186.065491 119.751999,185.835236 102.296768,186.116684 
	C72.221985,186.601562 43.812378,209.216858 35.752106,238.292374 
	C32.161457,251.244766 33.269539,264.388641 33.002365,278.500000 
M316.380402,102.242332 
	C316.049255,99.266129 315.927948,96.248878 315.354980,93.319977 
	C308.533875,58.449978 278.723938,33.289169 243.248123,33.087620 
	C196.258530,32.820648 149.266174,32.965401 102.275414,33.089249 
	C97.331154,33.102276 92.257355,33.632751 87.468132,34.824936 
	C53.856091,43.192001 33.073479,69.876907 33.011295,104.475967 
	C32.965473,129.971115 32.998352,155.466385 33.012852,180.961609 
	C33.013638,182.345917 33.168453,183.730133 33.272564,185.464096 
	C34.144184,184.848206 34.574852,184.615067 34.919201,184.290375 
	C56.001785,164.412201 81.245178,155.864487 109.976685,155.941071 
	C176.797012,156.119171 243.618134,155.997818 310.438934,155.997803 
	C312.391937,155.997803 314.344940,155.997803 316.442200,155.997803 
	C316.442200,138.052277 316.442200,120.607178 316.380402,102.242332 
M190.304382,413.057709 
	C202.867203,447.065552 234.462708,468.861115 271.794434,468.969330 
	C313.955811,469.091583 356.117981,469.052551 398.279358,468.928040 
	C403.567291,468.912415 408.986237,468.525085 414.117157,467.332184 
	C447.050201,459.675293 468.781036,432.316223 468.974182,398.446899 
	C469.097748,376.783478 468.997650,355.118744 468.997833,333.454620 
	C468.997833,332.387421 468.997803,331.320221 468.997803,330.479126 
	C460.720642,336.036926 453.069519,341.891327 444.766602,346.595306 
	C429.055511,355.496399 411.807800,358.609924 393.856079,358.594330 
	C326.364014,358.535614 258.871887,358.568542 191.379761,358.582703 
	C189.468094,358.583099 187.556458,358.758820 185.297531,358.869873 
	C185.297531,365.246063 185.298462,371.054810 185.297348,376.863586 
	C185.295044,388.891357 185.878967,400.840271 190.304382,413.057709 
M259.500000,186.000336 
	C234.910080,186.000336 210.320160,186.000336 185.556778,186.000336 
	C185.556778,233.656067 185.556778,280.719452 185.556778,328.065125 
	C229.325836,328.065125 272.832367,328.065125 316.555084,328.065125 
	C316.555084,280.640106 316.555084,233.581787 316.555084,186.000336 
	C297.710510,186.000336 279.105255,186.000336 259.500000,186.000336 
z"/>
        <path fill="#FFFFFF"
              d="
M468.997650,237.000000 
	C468.516449,248.962616 469.481293,260.711273 467.281128,271.833862 
	C460.849243,304.349243 434.439819,326.544312 401.045258,328.031311 
	C383.258453,328.823303 365.407349,328.171753 347.105530,328.171753 
	C347.008057,326.773438 346.819641,325.337555 346.820709,323.901825 
	C346.855347,277.073669 347.000702,230.245377 346.923340,183.417450 
	C346.877655,155.762085 346.981659,128.091324 346.094452,100.457146 
	C345.335541,76.818741 335.901947,56.297169 320.385132,38.539268 
	C319.070679,37.034946 317.783752,35.506401 316.499115,33.976437 
	C316.430267,33.894436 316.502136,33.694290 316.532410,33.002514 
	C318.061340,33.002514 319.643921,33.002491 321.226532,33.002522 
	C347.058136,33.002983 372.890320,32.897404 398.721252,33.030231 
	C432.479645,33.203823 460.001282,55.238075 467.373596,88.156693 
	C468.632935,93.779770 468.914001,99.715286 468.930359,105.508492 
	C469.053619,149.172043 468.997620,192.836105 468.997650,237.000000 
z"/>
        <path fill="#FFFFFF"
              d="
M33.002365,278.000000 
	C33.269539,264.388641 32.161457,251.244766 35.752106,238.292374 
	C43.812378,209.216858 72.221985,186.601562 102.296768,186.116684 
	C119.751999,185.835236 137.215485,186.065491 155.281982,186.065491 
	C155.281982,188.317093 155.284088,190.250046 155.281662,192.182999 
	C155.214386,245.823746 155.013321,299.464844 155.160812,353.105011 
	C155.204697,369.064056 155.222397,385.134949 157.025787,400.952393 
	C160.061661,427.579987 172.848022,449.672455 192.452652,468.549225 
	C191.130966,468.727783 190.132721,468.979797 189.134308,468.980469 
	C160.313599,469.000488 131.491882,469.145325 102.672401,468.954712 
	C69.633522,468.736237 42.017750,446.517395 34.734707,414.276093 
	C33.465248,408.656311 33.095295,402.733215 33.074989,396.947540 
	C32.936413,357.465424 33.002377,317.982605 33.002365,278.000000 
z"/>
        <path fill="#FFFFFF"
              d="
M316.411316,102.702202 
	C316.442200,120.607178 316.442200,138.052277 316.442200,155.997803 
	C314.344940,155.997803 312.391937,155.997803 310.438934,155.997803 
	C243.618134,155.997818 176.797012,156.119171 109.976685,155.941071 
	C81.245178,155.864487 56.001785,164.412201 34.919201,184.290375 
	C34.574852,184.615067 34.144184,184.848206 33.272564,185.464096 
	C33.168453,183.730133 33.013638,182.345917 33.012852,180.961609 
	C32.998352,155.466385 32.965473,129.971115 33.011295,104.475967 
	C33.073479,69.876907 53.856091,43.192001 87.468132,34.824936 
	C92.257355,33.632751 97.331154,33.102276 102.275414,33.089249 
	C149.266174,32.965401 196.258530,32.820648 243.248123,33.087620 
	C278.723938,33.289169 308.533875,58.449978 315.354980,93.319977 
	C315.927948,96.248878 316.049255,99.266129 316.411316,102.702202 
z"/>
        <path fill="#FFFFFF"
              d="
M190.180725,412.682129 
	C185.878967,400.840271 185.295044,388.891357 185.297348,376.863586 
	C185.298462,371.054810 185.297531,365.246063 185.297531,358.869873 
	C187.556458,358.758820 189.468094,358.583099 191.379761,358.582703 
	C258.871887,358.568542 326.364014,358.535614 393.856079,358.594330 
	C411.807800,358.609924 429.055511,355.496399 444.766602,346.595306 
	C453.069519,341.891327 460.720642,336.036926 468.997803,330.479126 
	C468.997803,331.320221 468.997833,332.387421 468.997833,333.454620 
	C468.997650,355.118744 469.097748,376.783478 468.974182,398.446899 
	C468.781036,432.316223 447.050201,459.675293 414.117157,467.332184 
	C408.986237,468.525085 403.567291,468.912415 398.279358,468.928040 
	C356.117981,469.052551 313.955811,469.091583 271.794434,468.969330 
	C234.462708,468.861115 202.867203,447.065552 190.180725,412.682129 
z"/>
        <path fill="#FFFFFF"
              d="
M260.000000,186.000336 
	C279.105255,186.000336 297.710510,186.000336 316.555084,186.000336 
	C316.555084,233.581787 316.555084,280.640106 316.555084,328.065125 
	C272.832367,328.065125 229.325836,328.065125 185.556778,328.065125 
	C185.556778,280.719452 185.556778,233.656067 185.556778,186.000336 
	C210.320160,186.000336 234.910080,186.000336 260.000000,186.000336 
z"/>
    </svg>,
    'LumenLogo'
);

export default LumenLogo;
