/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export enum AddressCategory {
  Home = "Home",
  Work = "Work",
  Other = "Other",
}

export interface AddressViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  createdByData?: ComboModel;
  category?: AddressCategory;
  /** @format uuid */
  contactId?: string;
  country: string | null;
  district: string | null;
  county?: string | null;
  subCounty?: string | null;
  village?: string | null;
  parish?: string | null;
  postalCode?: string | null;
  street?: string | null;
  freeForm?: string | null;
  placeId?: string | null;
  latLon?: string | null;
  /** @format date-time */
  startDate?: string | null;
  /** @format date-time */
  endDate?: string | null;
  isPrimary?: boolean;
}

export interface AgentLink {
  entityName?: string | null;
  linkMatchType?: string | null;
  fcsNumber?: string | null;
}

export interface ApiKeyResetResponse {
  key?: string | null;
}

export interface ApiKeyViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  createdByData?: ComboModel;
  ownerName?: string | null;
  /** @format uuid */
  partnerId?: string;
  isActive?: boolean;
  role?: string | null;
  /** @format date-time */
  lastUsedAt?: string | null;
}

export interface BankAccountViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  createdByData?: ComboModel;
  bankName?: string | null;
  bankCode: string | null;
  branchName?: string | null;
  branchCode: string | null;
  accountName?: string | null;
  accountNumber: string | null;
  swiftCode?: string | null;
  /** @format uuid */
  contactId?: string;
  isPrimary?: boolean;
}

export interface BaseApproveModel {
  /** @format uuid */
  id?: string;
  /** @minLength 1 */
  remarks: string;
  decision?: boolean;
}

export interface BaseResponse {
  message?: string | null;
  status?: boolean;
  data?: Record<string, string>;
}

export interface BouncedCheque {
  subscriber?: string | null;
  issueDate?: string | null;
  accountNumber?: string | null;
  chequeNumber?: string | null;
  amount?: string | null;
  currency?: string | null;
  reason?: string | null;
}

export interface BulkCreditReferenceImportRequest {
  /** @format uuid */
  walletId?: string;
  /** @format uuid */
  docId?: string;
  externalReference?: string | null;
  message?: string | null;
}

export interface BulkCreditReferencePreview {
  /** @format uuid */
  docId?: string;
  document?: DocViewModel;
  /** @format int32 */
  totalRecords?: number;
  errors?: ImportError[] | null;
  sample?: CreditReferenceRequestModel[] | null;
}

export enum BulkCreditReferenceStatus {
  Pending = "Pending",
  Processing = "Processing",
  Completed = "Completed",
  Error = "Error",
}

export interface BulkCreditReferenceViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  status?: BulkCreditReferenceStatus;
  /** @format uuid */
  partnerId?: string;
  partner?: ComboModel;
  /** @format int32 */
  totalRequests?: number;
  /** @format int32 */
  completedRequests?: number;
  /** @format date-time */
  startedAt?: string | null;
  /** @format date-time */
  completedAt?: string | null;
  externalReference?: string | null;
  /** @format uuid */
  documentId?: string | null;
  document?: DocViewModel;
  createdBy?: string | null;
  createdByData?: ComboModel;
  message?: string | null;
}

export interface BulkCreditReferenceViewModelPaginatedListViewModel {
  /** @format int32 */
  page?: number;
  /** @format int32 */
  totalPages?: number;
  /** @format int32 */
  total?: number;
  data?: BulkCreditReferenceViewModel[] | null;
  hasPreviousPage?: boolean;
  hasNextPage?: boolean;
}

export enum CivilStatus {
  Single = "Single",
  Married = "Married",
  Divorced = "Divorced",
  Other = "Other",
}

export interface Codix {
  productCode?: string | null;
  productDescription?: string | null;
  outcome?: string | null;
  reasons?: Reason[] | null;
}

export interface Collateral {
  heldBy?: string | null;
  maturityDate?: string | null;
  clientNumber?: string | null;
  collateralType?: string | null;
  referenceNumber?: string | null;
  forcedSaleValue?: string | null;
  currency?: string | null;
  valuationDate?: string | null;
  expiryDate?: string | null;
  instrumentOfClaim?: string | null;
  description?: string | null;
  accountNumber?: string | null;
  openMarketValue?: string | null;
}

export interface ComboModel {
  id: string | null;
  name: string | null;
}

export enum CompanyCategory {
  PrivateLimited = "PrivateLimited",
  PublicLimited = "PublicLimited",
  SoleProprietorship = "SoleProprietorship",
  Partnership = "Partnership",
  Other = "Other",
}

export interface CompanyInfo {
  name?: string | null;
  email?: string | null;
  phone?: string | null;
}

export interface CompanyViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  createdByData?: ComboModel;
  category?: CompanyCategory;
  name: string | null;
  registrationNumber: string | null;
  /** @format date */
  registrationDate?: string | null;
  /** @format int32 */
  numberOfEmployees?: number | null;
  /** @format uuid */
  contactId?: string;
  /** @format uuid */
  contactPersonId?: string | null;
  contactPerson?: ContactMiniViewModel;
  /** @format uuid */
  accountManagerId?: string | null;
  accountManager?: ContactMiniViewModel;
  /** @maxLength 200 */
  countryOfOperation?: string | null;
  /** @maxLength 200 */
  industry?: string | null;
  acceptedTermsAndCondition?: boolean;
}

export interface Compuscore {
  scoreName?: string | null;
  scoreValue?: string | null;
  relationshipType?: string | null;
}

export enum ContactCategory {
  Person = "Person",
  Company = "Company",
}

export interface ContactInformation {
  fullContactData?: string | null;
  contactType?: string | null;
  dateRegistered?: string | null;
}

export interface ContactMiniViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  /** @format uuid */
  contactPersonId?: string | null;
  name: string | null;
  email?: string | null;
  phone?: string | null;
}

export interface ContactTagViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  createdByData?: ComboModel;
  /** @format uuid */
  contactId?: string;
  value: string | null;
}

export enum ContactUrlCategory {
  Website = "Website",
  Facebook = "Facebook",
  Linkedin = "Linkedin",
  Twitter = "Twitter",
  Other = "Other",
}

export interface ContactUrlViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  createdByData?: ComboModel;
  /** @format uuid */
  contactId?: string;
  category?: ContactUrlCategory;
  value: string | null;
  isPrimary?: boolean;
}

export interface ContactViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  createdByData?: ComboModel;
  category?: ContactCategory;
  person?: PersonViewModel;
  company?: CompanyViewModel;
  /** @format uuid */
  partnerId?: string;
  partner?: ContactMiniViewModel;
  occasions?: EventViewModel[] | null;
  identifications?: IdentificationViewModel[] | null;
  phones?: PhoneViewModel[] | null;
  emails?: EmailViewModel[] | null;
  addresses?: AddressViewModel[] | null;
  urls?: ContactUrlViewModel[] | null;
  tags?: ContactTagViewModel[] | null;
  bankAccounts?: BankAccountViewModel[] | null;
}

export interface CreditAccountsDetail {
  subscriber?: string | null;
  reportingDate?: string | null;
  transactionDate?: string | null;
  classification?: string | null;
  loanPurpose?: string | null;
  overdueBalance?: string | null;
  groupNumber?: string | null;
  accountType?: string | null;
  accountOpenDate?: string | null;
  clientNumber?: string | null;
  accountReference?: string | null;
  accountStatus?: string | null;
  riskClassification?: string | null;
  clientAdviceGiven?: string | null;
  consentObtained?: string | null;
  creditAmount?: string | null;
  term?: string | null;
  monthlyInstalment?: string | null;
  currentBalance?: string | null;
  currency?: string | null;
  arrearsDate?: string | null;
  daysInArrears?: string | null;
  lastPaymentDate?: string | null;
  lastStatusChangeDate?: string | null;
  lastPaymentAmount?: string | null;
  numberOfPayments?: string | null;
  paymentFrequency?: string | null;
  dateOfFirstPayment?: string | null;
  facilityAmount?: string | null;
  annualInterestRateAtDisbursement?: string | null;
  annualInterestRateAtReporting?: string | null;
  interestCalculationMethod?: string | null;
  provisionAmount?: string | null;
  amortizationType?: string | null;
  typeOfInterest?: string | null;
  restructuredCredit?: string | null;
  maturityDate?: string | null;
  closureDate?: string | null;
  closureReason?: string | null;
  creditCycleDays?: string | null;
  creditCycleRisk?: string | null;
  creditCycleStatus?: string | null;
  restructureMode?: string | null;
  restructureModeDate?: string | null;
}

export interface CreditAccountsSummary {
  subscriber?: string | null;
  reportingDate?: string | null;
  clientNumber?: string | null;
  accountNumber?: string | null;
  groupId?: string | null;
  transactionDate?: string | null;
  term?: string | null;
  maturityDate?: string | null;
  balanceOverdue?: string | null;
  balanceOverdueCurrency?: string | null;
  worstDaysEver?: string | null;
  worstDaysEverDate?: string | null;
  currentBalance?: string | null;
  currentBalanceCurrency?: string | null;
  instalment?: string | null;
  instalmentCurrency?: string | null;
  status?: string | null;
  statusDate?: string | null;
  accountType?: string | null;
  restructureMode?: string | null;
  restructureModeDate?: string | null;
  guarantors?: Guarantor[] | null;
  paymentProfiles?: PaymentProfile[] | null;
}

export interface CreditDataSubmissionRequest {
  fileType?: SubmissionFileType;
  isSubmit?: boolean;
  /** @maxLength 100 */
  externalReference?: string | null;
  /** @maxLength 200 */
  notes?: string | null;
  data?: Record<string, string>[] | null;
}

export enum CreditRefIdCategory {
  NationalId = "NationalId",
  FinancialCard = "FinancialCard",
  Passport = "Passport",
  RefugeeId = "RefugeeId",
  WorkPermit = "WorkPermit",
  BusinessRegistration = "BusinessRegistration",
  TaxIdentification = "TaxIdentification",
}

export interface CreditReferenceRequestModel {
  referenceId?: string | null;
  isBlocking?: boolean;
  /** @format uuid */
  customerId?: string | null;
  /** @format uuid */
  bulkId?: string | null;
  identificationValue?: string | null;
  identificationCategory?: CreditRefIdCategory;
  customerPhoneNumber?: string | null;
  inquiryReason?: string | null;
  creditReferenceType?: CreditReferenceType;
}

export enum CreditReferenceType {
  Individual = "Individual",
  Business = "Business",
}

export interface CreditReferenceViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  createdByData?: ComboModel;
  /** @format uuid */
  partnerId?: string;
  partner?: ComboModel;
  /** @format date-time */
  sentAt?: string | null;
  category?: string | null;
  /** @format uuid */
  productId?: string;
  /** @format uuid */
  bulkId?: string | null;
  /** @format uuid */
  customerId?: string | null;
  status?: RequestStatus;
  /** @format date-time */
  startedAt?: string | null;
  /** @format int64 */
  runningBalance?: number;
  /** @format date-time */
  processedAt?: string | null;
  /** @format int32 */
  attemptCount?: number;
  channel?: string | null;
  requestedBy?: string | null;
  requestedByData?: ComboModel;
  referenceId?: string | null;
  errorCode?: string | null;
  errorMessage?: string | null;
  identificationValue?: string | null;
  identificationCategory?: CreditRefIdCategory;
  sessionId?: string | null;
  statusCode?: string | null;
  creditReferenceType?: CreditReferenceType;
  /** @format uuid */
  documentId?: string | null;
  document?: DocViewModel;
  inquiryNumber?: string | null;
  inquiryReason?: string | null;
  customerPhoneNumber?: string | null;
  responseData?: ExperianCreditReferenceResponse;
}

export interface CreditReferenceViewModelPaginatedListViewModel {
  /** @format int32 */
  page?: number;
  /** @format int32 */
  totalPages?: number;
  /** @format int32 */
  total?: number;
  data?: CreditReferenceViewModel[] | null;
  hasPreviousPage?: boolean;
  hasNextPage?: boolean;
}

export interface Cumulative {
  amount?: string | null;
  currency?: string | null;
}

export enum DataValidationStatus {
  Pending = "Pending",
  Initiated = "Initiated",
  Processing = "Processing",
  Successful = "Successful",
  Failed = "Failed",
}

export interface DocViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  createdByData?: ComboModel;
  name?: string | null;
  contentType?: string | null;
  originalName?: string | null;
  description?: string | null;
  size?: string | null;
  /** @format uuid */
  partnerId?: string;
}

export interface EConsentConfigurationViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  createdByData?: ComboModel;
  isEnabled?: boolean;
  active?: boolean;
  /** @format uuid */
  partnerId?: string;
}

export enum EmailCategory {
  Personal = "Personal",
  Work = "Work",
  Other = "Other",
}

export interface EmailViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  createdByData?: ComboModel;
  category?: EmailCategory;
  /** @format uuid */
  contactId?: string;
  value: string | null;
  isPrimary?: boolean;
}

export interface Employment {
  dateRegistered?: string | null;
  employerName?: string | null;
  occupation?: string | null;
  incomeBand?: string | null;
  employeeNumber?: string | null;
  salaryFrequency?: string | null;
  employmentDate?: string | null;
}

export interface EnquiryCriteria {
  enquiryId?: string | null;
  enquiryType?: string | null;
  criteriaDataType?: string | null;
  criteriaData?: string | null;
  enquiryTimestamp?: string | null;
}

export enum EventCategory {
  Birthday = "Birthday",
  Anniversary = "Anniversary",
  Engaged = "Engaged",
  Other = "Other",
}

export interface EventViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  createdByData?: ComboModel;
  category?: EventCategory;
  details: string | null;
  /** @format date */
  value?: string;
  /** @format uuid */
  contactId?: string;
}

export interface ExperianCreditReferenceResponse {
  enquiryId?: string | null;
  isIndividual?: string | null;
  enquiryVersion?: string | null;
  businessName?: string | null;
  clientReferenceNumber?: string | null;
  products?: string[] | null;
  enquiryCriteria?: EnquiryCriteria;
  otherIdentifiers?: OtherIdentifiers[] | null;
  matchedData?: MatchedData;
  identifiers?: Identifier[] | null;
  summaryInformation?: SummaryInformation;
  fcsLink?: FcsLink[] | null;
  compuscores?: Compuscore[] | null;
  codixes?: Codix[] | null;
  financialMalpractice?: FinancialMalpractice[] | null;
  previousCreditApplications?: PreviousCreditApplication[] | null;
  previousEnquiries?: PreviousEnquiry[] | null;
  bouncedCheques?: BouncedCheque[] | null;
  stakeholder?: Stakeholder[] | null;
  employment?: Employment[] | null;
  adverseCreditAccountsSummary?: CreditAccountsSummary[] | null;
  creditAccountsSummary?: CreditAccountsSummary[] | null;
  securityCommitments?: SecurityCommitment[] | null;
  collateral?: Collateral[] | null;
  contactInformation?: ContactInformation[] | null;
  creditAccountsDetail?: CreditAccountsDetail[] | null;
}

export interface ExperianPartnerConfigCreateModel {
  /** @format uuid */
  partnerId: string;
  institutionName: string | null;
  branchIdentificationCode: string | null;
  participatingIdentificationCode: string | null;
  branchName: string | null;
  branchType: string | null;
  /** @format date-time */
  dateOpened: string;
  password: string | null;
  dsmVersion: string | null;
  userName: string | null;
}

export interface ExperianPartnerConfigEditModel {
  /** @format uuid */
  id?: string;
  /** @format uuid */
  partnerId: string;
  institutionName: string | null;
  branchIdentificationCode: string | null;
  participatingIdentificationCode: string | null;
  branchName: string | null;
  branchType: string | null;
  /** @format date-time */
  dateOpened: string;
  password: string | null;
  dsmVersion: string | null;
  userName: string | null;
}

export interface ExperianPartnerConfigViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  createdByData?: ComboModel;
  /** @format uuid */
  partnerId?: string;
  institutionName?: string | null;
  branchIdentificationCode?: string | null;
  participatingIdentificationCode?: string | null;
  branchName?: string | null;
  branchType?: string | null;
  /** @format date-time */
  dateOpened?: string | null;
  password?: string | null;
  dsmVersion?: string | null;
  userName?: string | null;
  /** @format date-time */
  lastUpdated?: string | null;
}

export interface ExperianPartnerConfigViewModelPaginatedListViewModel {
  /** @format int32 */
  page?: number;
  /** @format int32 */
  totalPages?: number;
  /** @format int32 */
  total?: number;
  data?: ExperianPartnerConfigViewModel[] | null;
  hasPreviousPage?: boolean;
  hasNextPage?: boolean;
}

export interface FcsLink {
  surname?: string | null;
  firstName?: string | null;
  secondName?: string | null;
  thirdName?: string | null;
  gender?: string | null;
  dob?: string | null;
  image?: string | null;
  agentLinks?: AgentLink[] | null;
}

export interface FinancialMalpractice {
  lossAmount?: string | null;
  incidentDetails?: string | null;
  incidentDate?: string | null;
  forensicInformationAvailable?: string | null;
  currency?: string | null;
  category?: string | null;
  subCategory?: string | null;
  subscriber?: string | null;
  confirmationStatus?: string | null;
  victimIndicator?: string | null;
}

export enum Gender {
  Female = "Female",
  Male = "Male",
}

export interface Guarantor {
  entityName?: string | null;
  classification?: string | null;
  valueOfGuarantee?: string | null;
  guarantorType?: string | null;
  guaranteeType?: string | null;
  idNumber?: string | null;
  idNumberType?: string | null;
}

export enum IdentificationCategory {
  NIN = "NIN",
  NSSF = "NSSF",
  TIN = "TIN",
  FCN = "FCN",
  Passport = "Passport",
  DrivingPermit = "DrivingPermit",
  VillageCard = "VillageCard",
  Other = "Other",
  BRN = "BRN",
}

export interface IdentificationViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  createdByData?: ComboModel;
  category?: IdentificationCategory;
  /** @format uuid */
  contactId?: string;
  value: string | null;
  issuingCountry?: string | null;
  /** @format date */
  issueDate?: string | null;
  /** @format date */
  expiryDate?: string | null;
  isPrimary?: boolean;
}

export interface Identifier {
  idNumber?: string | null;
  idNumberType?: string | null;
}

export interface ImportError {
  /** @format int32 */
  row?: number;
  /** @format int32 */
  col?: number;
  error?: string | null;
}

export enum IoTecPayCollectionStatus {
  Pending = "Pending",
  SentToVendor = "SentToVendor",
  Success = "Success",
  Failed = "Failed",
}

export interface IoTecPayCreditTopUpResponse {
  /** @format uuid */
  transactionId?: string;
  payer?: string | null;
  status?: IoTecPayCollectionStatus;
  currency?: string | null;
  /** @format double */
  amount?: number;
  statusCode?: string | null;
  statusMessage?: string | null;
  /** @format double */
  totalTransactionCharge?: number | null;
}

export interface MatchedData {
  surname?: string | null;
  firstName?: string | null;
  secondName?: string | null;
  thirdName?: string | null;
  dob?: string | null;
  gender?: string | null;
  maritalStatus?: string | null;
}

export interface NotFoundResult {
  /** @format int32 */
  statusCode?: number;
}

export interface OkResult {
  /** @format int32 */
  statusCode?: number;
}

export interface OtherIdentifiers {
  idNumber?: string | null;
  idNumberType?: string | null;
}

export interface PartnerClientIdViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  createdByData?: ComboModel;
  /** @maxLength 150 */
  clientId: string | null;
  partner?: ComboModel;
  /** @format uuid */
  partnerId?: string;
}

export interface PartnerCreateModel {
  /** @format uuid */
  id: string;
  partnerIps?: string[] | null;
  clientId?: string | null;
}

export interface PartnerIpViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  createdByData?: ComboModel;
  /** @minLength 1 */
  ipAddress: string;
}

export interface PartnerMemberCreateModel {
  /** @format uuid */
  id?: string;
  /** @format uuid */
  partnerId?: string;
  roles?: string[] | null;
}

export interface PartnerMemberEditModel {
  /** @format uuid */
  id?: string;
  /** @format uuid */
  partnerId?: string;
  roles?: string[] | null;
}

export interface PartnerMemberViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  createdByData?: ComboModel;
  /** @format uuid */
  partnerId?: string;
  partner?: ComboModel;
  /** @format date-time */
  lastUpdated?: string | null;
  isDeleted?: boolean;
  person?: ContactMiniViewModel;
  active?: boolean;
  roles?: string[] | null;
}

export interface PartnerUpdateModel {
  /** @format uuid */
  id?: string;
  active?: boolean;
  company?: CompanyInfo;
  partnerIps: string[];
}

export interface PartnerViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  createdByData?: ComboModel;
  clientId?: string | null;
  name?: string | null;
  active?: boolean;
  company?: XContactModel;
  apiKey?: ApiKeyViewModel;
  partnerIps?: PartnerIpViewModel[] | null;
  clientIds?: PartnerClientIdViewModel[] | null;
}

export interface PaymentProfile {
  ccDate?: string | null;
  daysInArrears?: string | null;
  riskClassification?: string | null;
  accountStatus?: string | null;
}

export interface PersonViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  createdByData?: ComboModel;
  /** @format uuid */
  contactId?: string;
  salutation?: Salutation;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  fullName?: string | null;
  gender?: Gender;
  civilStatus?: CivilStatus;
  /** @format date */
  dateOfBirth?: string | null;
  profession?: string | null;
}

export enum PhoneCategory {
  Mobile = "Mobile",
  Office = "Office",
  Home = "Home",
  Fax = "Fax",
  Other = "Other",
}

export interface PhoneViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  createdByData?: ComboModel;
  /** @format uuid */
  contactId?: string;
  category?: PhoneCategory;
  value: string | null;
  isPrimary?: boolean;
}

export interface PreviousCreditApplication {
  subscriber?: string | null;
  applicationReference?: string | null;
  applicationDate?: string | null;
  applicationAmount?: string | null;
  approvedAmount?: string | null;
  currency?: string | null;
  status?: string | null;
  statusDate?: string | null;
  rejectionReason?: string | null;
}

export interface PreviousEnquiry {
  subscriber?: string | null;
  enquiryType?: string | null;
  date?: string | null;
}

export enum ProductCategory {
  DataSubmission = "DataSubmission",
  CreditReference = "CreditReference",
}

export interface ProductCreditsIoTecPayTopUpModel {
  /** @format uuid */
  partnerId?: string;
  productCategory?: string | null;
  /** @format double */
  credits?: number;
  /** @format double */
  amountToPay?: number;
  /** @minLength 1 */
  memo: string;
  /**
   * @format tel
   * @minLength 1
   */
  mobileMoneyNumber: string;
}

export interface ProductCreditsWalletToWalletTopUpModel {
  /** @format uuid */
  partnerId?: string;
  productCategory?: string | null;
  /** @format double */
  credits?: number;
  /** @format double */
  amountToPay?: number;
  /** @minLength 1 */
  memo: string;
  /** @minLength 1 */
  walletCode: string;
  walletPin: string | null;
}

export enum ProductPaymentMethod {
  BankDeposit = "BankDeposit",
  MobileMoney = "MobileMoney",
  Free = "Free",
  IoTecWallet = "IoTecWallet",
}

export interface ProductPriceBand {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  /** @format date-time */
  lastUpdated?: string | null;
  /** @maxLength 50 */
  createdBy?: string | null;
  isDeleted?: boolean;
  /** @maxLength 50 */
  category?: string | null;
  /** @maxLength 35 */
  name?: string | null;
  /** @format int32 */
  lowerLimit?: number;
  /** @format int32 */
  upperLimit?: number | null;
  /** @format double */
  unitPrice?: number;
  /** @maxLength 3 */
  currency?: string | null;
}

export interface ProductPriceBandCreateModel {
  /** @format uuid */
  productId?: string;
  /**
   * @minLength 1
   * @maxLength 35
   */
  name: string;
  /** @format int32 */
  lowerLimit?: number;
  /** @format int32 */
  upperLimit?: number | null;
  /** @format double */
  unitPrice?: number;
}

export interface ProductPriceBandUpdateModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  createdByData?: ComboModel;
  /** @maxLength 35 */
  name?: string | null;
  /** @format int32 */
  lowerLimit?: number;
  /** @format int32 */
  upperLimit?: number | null;
  /** @format double */
  unitPrice?: number;
}

export interface ProductPriceBandViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  createdByData?: ComboModel;
  name?: string | null;
  /** @format int32 */
  lowerLimit?: number;
  /** @format int32 */
  upperLimit?: number | null;
  /** @format double */
  unitPrice?: number;
  /** @format double */
  purchasableCredits?: number;
  /** @format double */
  purchasableAmount?: number;
  category?: string | null;
  currency?: string | null;
}

export enum ProductPurchaseOrderInvoiceStatus {
  Pending = "Pending",
  Paid = "Paid",
  Cancelled = "Cancelled",
}

export interface ProductPurchaseOrderInvoiceViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  createdByData?: ComboModel;
  /** @format uuid */
  partnerId?: string;
  productCategory?: string | null;
  /** @format uuid */
  purchaseOrderId?: string;
  invoiceNumber: string | null;
  status?: ProductPurchaseOrderInvoiceStatus;
  /** @format date-time */
  dueDate?: string | null;
  memo: string | null;
  partnerAddress?: string | null;
  partnerEmail: string | null;
  partnerPhone: string | null;
  partnerName: string | null;
  /** @format double */
  amount?: number;
  /** @format double */
  taxAmount?: number;
  /** @format double */
  credits?: number;
  /** @format double */
  unitPrice?: number;
  /** @format uuid */
  updatedBy?: string | null;
}

export interface ProductPurchaseOrderIoTecPayViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  createdByData?: ComboModel;
  category: string | null;
  currency: string | null;
  walletId: string | null;
  externalId: string | null;
  /** @format uuid */
  partnerId?: string;
  partner?: ComboModel;
  payer: string | null;
  payerNote: string | null;
  /** @format double */
  amount?: number;
  payeeNote: string | null;
  channel?: string | null;
  transactionChargesCategory: string | null;
  productCategory?: string | null;
  status?: IoTecPayCollectionStatus;
  /** @format double */
  credits?: number;
  /** @format double */
  totalAmountPaid?: number;
  /** @format double */
  transactionCharge?: number;
  /** @format double */
  vendorCharge?: number;
  /** @format double */
  totalTransactionCharge?: number;
  payerName?: string | null;
  /** @format uuid */
  purchaseOrderId?: string;
}

export interface ProductPurchaseOrderPreviewRequestModel {
  /** @format uuid */
  partnerId?: string;
  productCategory?: string | null;
  /** @format double */
  credits?: number;
  paymentMethod?: ProductPaymentMethod;
  /** Added for mobile money payment method. */
  mobileMoneyNumber?: string | null;
  /** Added for iotec wallet payment method */
  walletCode?: string | null;
  /** Added for iotec wallet payment method */
  walletPin?: string | null;
}

export interface ProductPurchaseOrderPreviewResponseModel {
  /** @format uuid */
  partnerId?: string;
  productCategory?: string | null;
  /** @format double */
  credits?: number;
  paymentMethod?: ProductPaymentMethod;
  /** @format double */
  amount?: number;
  /** @format double */
  transactionCharges?: number;
  /** Added for mobile money payment method. */
  mobileMoneyNumber?: string | null;
  /** Added for wallet code payment method. */
  walletCode?: string | null;
  /** Added for wallet code payment method. */
  walletPin?: string | null;
}

export enum ProductPurchaseOrderStatus {
  Pending = "Pending",
  Paid = "Paid",
  Cancelled = "Cancelled",
}

export interface ProductPurchaseOrderViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  createdByData?: ComboModel;
  productCategory?: string | null;
  paymentMethod?: ProductPaymentMethod;
  /** @format uuid */
  partnerId?: string;
  partner?: ComboModel;
  /** @format uuid */
  requestId?: string;
  memo: string | null;
  /** @format double */
  amount?: number;
  /** @format double */
  credits?: number;
  /** @format uuid */
  purchaseOrderDetailId?: string;
  invoiceDetail?: ProductPurchaseOrderInvoiceViewModel;
  ioTecPayDetail?: ProductPurchaseOrderIoTecPayViewModel;
  /** @format uuid */
  priceBandId?: string;
  priceBand?: ProductPriceBand;
}

export interface ProductPurchaseOrderViewModelPaginatedListViewModel {
  /** @format int32 */
  page?: number;
  /** @format int32 */
  totalPages?: number;
  /** @format int32 */
  total?: number;
  data?: ProductPurchaseOrderViewModel[] | null;
  hasPreviousPage?: boolean;
  hasNextPage?: boolean;
}

export interface ProductSubscriptionViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  createdByData?: ComboModel;
  category?: string | null;
  /** @format double */
  creditsBalance?: number;
  /** @format uuid */
  partnerId?: string;
  partner?: ComboModel;
  /** @format uuid */
  priceBandId?: string;
  priceBand?: ComboModel;
}

export interface ProductTopUpInvoiceCreateModel {
  /** @format uuid */
  partnerId?: string;
  productCategory?: string | null;
  /** @format uuid */
  requestId?: string;
  /** @format date-time */
  dueDate?: string;
  memo: string | null;
  /** @format double */
  amount?: number;
  /** @format double */
  taxAmount?: number;
  /** @format double */
  credits: number;
}

export interface ProductTopUpInvoicePayModel {
  /** @format uuid */
  invoiceId?: string;
  /** @format uuid */
  partnerId?: string;
  memo: string | null;
}

export interface Reason {
  reasonReason?: string | null;
  outcome?: string | null;
}

export enum RequestStatus {
  Pending = "Pending",
  Processing = "Processing",
  Completed = "Completed",
  Failed = "Failed",
}

export enum Salutation {
  Mr = "Mr",
  Mrs = "Mrs",
  Ms = "Ms",
  Dr = "Dr",
  Prof = "Prof",
  Other = "Other",
}

export interface SecurityCommitment {
  subscriber?: string | null;
  clientNumber?: string | null;
  maturityDate?: string | null;
  accountNumber?: string | null;
  guaranteeValue?: string | null;
  guarantorType?: string | null;
  guaranteeType?: string | null;
}

export interface Stakeholder {
  dateRegistered?: string | null;
  stakeholderCategory?: string | null;
  shareholdingPercentage?: string | null;
  stakeholderIn?: string | null;
}

export enum SubmissionFileType {
  ParticipatingInstitution = "ParticipatingInstitution",
  InstitutionBranch = "InstitutionBranch",
  CreditApplication = "CreditApplication",
  BorrowerCreditAccount = "BorrowerCreditAccount",
  BouncedCheques = "BouncedCheques",
  ParticipatingInstitutionStakeholders = "ParticipatingInstitutionStakeholders",
  BorrowerStakeholders = "BorrowerStakeholders",
  CollateralMaterialCollateral = "CollateralMaterialCollateral",
  CollateralCreditGuarantor = "CollateralCreditGuarantor",
  FinancialMalpracticeData = "FinancialMalpracticeData",
}

export interface SubmissionRequestImportModel {
  fileType?: SubmissionFileType;
  isSubmit?: boolean;
  /** @format uuid */
  docId?: string;
  /** @maxLength 100 */
  externalReference?: string | null;
  /** @maxLength 200 */
  notes?: string | null;
}

export interface SubmissionRequestUploadModel {
  /** @format binary */
  file?: File | null;
  fileType?: SubmissionFileType;
}

export interface SubmissionRequestViewModel {
  /** @format uuid */
  id?: string;
  /** @format date-time */
  createdAt?: string;
  createdBy?: string | null;
  createdByData?: ComboModel;
  submissionStatus?: SubmissionStatus;
  dataValidationStatus?: DataValidationStatus;
  /** @format uuid */
  partnerId?: string;
  partner?: ComboModel;
  submittedBy?: string | null;
  submittedByData?: ComboModel;
  submissionFileType?: SubmissionFileType;
  /** @format date-time */
  submissionDate?: string;
  externalReference?: string | null;
  /** @format uuid */
  documentId?: string | null;
  document?: DocViewModel;
  submissionFileName?: string | null;
  /** @format uuid */
  submissionFileId?: string | null;
  submissionFile?: DocViewModel;
  /** @format uuid */
  responseCsvFileId?: string | null;
  /** @format uuid */
  responsePdfFileId?: string | null;
  /** @format int32 */
  totalRecords?: number;
  published?: boolean | null;
  notes?: string | null;
}

export interface SubmissionRequestViewModelPaginatedListViewModel {
  /** @format int32 */
  page?: number;
  /** @format int32 */
  totalPages?: number;
  /** @format int32 */
  total?: number;
  data?: SubmissionRequestViewModel[] | null;
  hasPreviousPage?: boolean;
  hasNextPage?: boolean;
}

export enum SubmissionStatus {
  Pending = "Pending",
  Processing = "Processing",
  Completed = "Completed",
  Validating = "Validating",
  SavedForLater = "SavedForLater",
  Failed = "Failed",
  Uploading = "Uploading",
  UnableToSubmit = "UnableToSubmit",
  FailedValidationAtCrb = "FailedValidationAtCrb",
}

export interface SummaryInformation {
  worstDaysInArrears?: string | null;
  openAccounts?: string | null;
  accountsAtAnotherPi?: string | null;
  ownAccounts?: string | null;
  adverseAccounts?: string | null;
  bouncedCheques?: string | null;
  financialMalpractice?: string | null;
  guarantors?: string | null;
  collaterals?: string | null;
  securityCommitments?: string | null;
  groups?: string | null;
  creditApplications?: string | null;
  pendingApplications?: string | null;
  approvedApplications?: string | null;
  rejectedApplications?: string | null;
  cancelledApplications?: string | null;
  stakeholder?: string | null;
  traces?: string | null;
  contacts?: string | null;
  closedAccounts?: string | null;
  cumulativeArrears?: Cumulative[] | null;
  cumulativeCurrentBalance?: Cumulative[] | null;
  cumulativeMonthlyInstalment?: Cumulative[] | null;
}

export interface SystemRole {
  id?: string | null;
  name?: string | null;
  description?: string | null;
  requiresPrimaryUser?: boolean;
}

export interface UnauthorizedResult {
  /** @format int32 */
  statusCode?: number;
}

export interface XContactModel {
  id?: string | null;
  name?: string | null;
  email?: string | null;
  phone?: string | null;
}
