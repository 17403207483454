import React from 'react';
import { useField } from 'formik';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { hasValue } from './inputHelpers';
import { useTheme } from '@mui/material/styles';

interface IProps {
  name: string
  label: string
  inputFormat?: string
  variant?: 'outlined' | 'filled' | 'standard'
  margin?: 'dense' | 'normal' | 'none';
  pickerVariant?: 'inline' | 'dialog' | 'static'
  size?: 'small' | 'medium';
  fullWidth?: boolean;
  disableFuture?: boolean;
  disablePast?: boolean;
}
const isoDateFormat = 'YYYY-MM-DD';
export const dateFormats = ['YYYY-MM-DDTHH:mm:ssZ', 'DD/MM/YYYY',  'YYYY/MM/DD', 'YYYY-MM-DD', 'YYYY.MM.DD', 'DD.MM.YYYY'];

const XDateInput = (props: IProps) => {
  const theme = useTheme();

  const {
    label,
    size,
    variant,
    fullWidth = true,
    name,
    margin = 'dense',
    inputFormat = 'DD/MM/YYYY',
    ...extraProps
  } = props;
  const [{ value }, meta, { setValue }] = useField({ name });
  const error = hasValue(meta.error) ? meta.error : undefined;
  const showError = Boolean(error && meta.touched);

  const handleChange = (date: Dayjs) => {
    setValue(date?.format(isoDateFormat));
  };

  return (
      <DatePicker
          {...extraProps}
          label={label}
          value={hasValue(value) ? dayjs(value, [...dateFormats, inputFormat]) : null}
          onChange={handleChange}
          openTo='year'
          format={inputFormat}
          disableFuture={props.disableFuture}
          disablePast={props.disablePast}
          slotProps={{
            textField: {
              variant,
              size,
              fullWidth,
              helperText: showError ? error : null,
              error: Boolean(showError),
              margin
            },
            layout: {
              sx: {
                boxShadow: theme.shadows[10],
                borderRadius: '16px'
              }
            }
          }}
      />
  );
};

export default XDateInput;
