import 'date-fns';
import React from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { hasValue } from '../inputs/inputHelpers';

interface IProps {
  onChange: (date: Date | null) => any
  value: Date | null
  label?: string
  name?: string
  variant?: 'inline'
  inputVariant?: 'standard' | 'outlined' | 'filled'
  size?: 'small' | 'medium';
  fullWidth?: boolean;
  inputFormat?: string
}

export default function PDateInput({ value = null, onChange, label, size, fullWidth = true, inputVariant, inputFormat = 'DD/MM/YYYY', ...props }: IProps) {
  const handleChange = (date: Dayjs | null) => {
    if (date === null) {
      onChange(null);
    } else {
      onChange(date.toDate());
    }
  };
  return (
      <DatePicker
          label={label}
          value={hasValue(value) ? dayjs(value) : null}
          onChange={handleChange}
          format={inputFormat}
          slotProps={{
            textField: {
              variant: inputVariant,
              size,
              fullWidth,
            } }}
          {...props}
      />
  );
}
