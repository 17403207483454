import React, { useState, useEffect } from 'react';
import { Box, FormHelperText } from '@mui/material';
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import Loading from '../../../components/loaders/Loading';
import { ProfileLayout } from '../profile/ProfileLayout';

const CallBack:React.FC<React.PropsWithChildren<any>> = (props) => {
  const isMountedRef = useIsMountedRef();
  const { loginRedirectCallback, loadUser } = useAuth();
  const [error, setError] = useState(null);

  useEffect(() => {
    const authCallBack = async () => {
      try {
        await loginRedirectCallback();
        await loadUser();
      } catch (err) {
        console.error(err);
        if (isMountedRef.current) {
          setError(err.message);
        }
      }
    };
    authCallBack();
  }, []);

  return (
    <ProfileLayout title="Authenticating...">
      <div {...props}>
        {error ? (
          <Box sx={{ my: 3 }}>
            <FormHelperText error>
              {error}
            </FormHelperText>
          </Box>
        ) : (
          <Loading/>
        )}
      </div>
    </ProfileLayout>
  );
};

export default CallBack;
