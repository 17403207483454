import { FormikHelpers } from 'formik';
import * as yup from 'yup';
import { handleError, post, put } from './ajax';
import Toast from './Toast';
import { IColumn, InputType } from '../components/dynamic-editor/types';
import { cleanComboValue } from './dataHelpers';
import { hasValue } from '../components/inputs/inputHelpers';

export interface ISubmission {
  url: string
  values: any
  isNew: boolean
  actions: FormikHelpers<any>
  onAjaxComplete?: (data: any) => any
}

export function handleSubmission(submission: ISubmission) {
  const { isNew, actions, values, onAjaxComplete, url } = submission;
  if (isNew) {
    post(url, values,
      (data) => {
        Toast.info('Operation successful');
        actions.resetForm();
        if (onAjaxComplete)onAjaxComplete(data);
      },
      (err, resp) => {
        handleError(err, resp);
      }, () => {
        actions.setSubmitting(false);
      });
  } else {
    put(url, values,
      (data) => {
        Toast.info('Update successful');
        actions.resetForm();
        if (onAjaxComplete)onAjaxComplete(data);
      },
      (err, resp) => {
        handleError(err, resp);
      }, () => {
        actions.setSubmitting(false);
      });
  }
}

export function serialize(obj:any, prefix?:string) {
  console.log('Filters', { obj });
  const str = [];
  Object.entries(obj).filter((it) => {
    const [, v] = it;
    return hasValue(v);
  }).forEach((e:any) => {
    const [p, v] = e;
    const k = prefix ? `${prefix}[${p}]` : p;
    str.push((v !== null && typeof v === 'object')
      ? serialize(v, k)
      : `${encodeURIComponent(k)}=${encodeURIComponent(v)}`);
  });
  return str.join('&');
}

export const sanitizeFormValues = (fields:IColumn[], data:any):any => {
  const cleanValues = { ...data };
  fields.forEach(({ name, inputProps: { inputType } }) => {
    if (inputType === InputType.Select || inputType === InputType.RemoteSelect) {
      cleanValues[name] = cleanComboValue(data[name]);
    }
  });
  return cleanValues;
};

export type FormConfig = {
  initialValues:any
  schemaObject:any
  formFields:IColumn[]
};

export const sanitizeFormFields = (iColumns:IColumn[]):FormConfig => {
  const initialValues: any = {};
  const schemaObject: any = {};
  const formFields = iColumns.map(({ name, inputProps: { defaultValue, schema, ...inputProps }, ...rest }) => {
    initialValues[name] = defaultValue;
    schemaObject[name] = schema;
    return { name, inputProps, ...rest };
  }).filter((it) => !it?.inputProps?.hidden);

  return {
    initialValues,
    schemaObject: yup.object().shape(schemaObject),
    formFields
  };
};
