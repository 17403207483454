import { hasNoValue } from 'components/inputs/inputHelpers';
import {
  AddressViewModel,
  ContactCategory,
  ContactViewModel,
  EmailViewModel,
  IdentificationViewModel,
  PhoneViewModel
} from '__generated__/lumenTypes';

export const getFullName = (contact: ContactViewModel, salutation?: boolean): string => {
  if (hasNoValue(contact.person)) {
    const ct = contact as any;
    return ct.name;
  }
  if (contact.category === ContactCategory.Person) {
    const { person } = contact;
    const name: string = salutation
      ? `${person.salutation || ''} ${person.firstName || ''} ${person.middleName || ''} ${person.lastName || ''}`
      : `${person.firstName || ''} ${person.middleName || ''} ${person.lastName || ''}`;

    return name.trim()
      .replace(/\s+/g, ' ');
  }
  console.log(contact);
  return contact.company.name;
};

export const printAddress = (data: AddressViewModel): string => {
  const address: string = `${data.street || ''} ${data.parish || ''} ${data.district || ''} ${data.country || ''}`;
  return address.trim()
    .replace(/\s+/g, ' ');
};

export const getPhone = (data: ContactViewModel): string => {
  const { phones } = data;
  if (phones && phones.length > 0) {
    const pri = phones.find((it) => it.isPrimary);
    if (pri) {
      return pri.value;
    }
    return phones[0].value;
  }
  return '';
};

export const getPhoneObj = (data: ContactViewModel): PhoneViewModel => {
  const { phones } = data;
  if (phones && phones.length > 0) {
    const pri = phones.find((it) => it.isPrimary);
    if (pri) {
      return pri;
    }
    return phones[0];
  }
  return {} as PhoneViewModel;
};

export const getEmail = (data: ContactViewModel): string => {
  const { emails } = data;
  if (emails && emails.length > 0) {
    const pri = emails.find((it) => it.isPrimary);
    if (pri) {
      return pri.value;
    }
    return emails[0].value;
  }
  return '';
};

export const getEmailObj = (data: ContactViewModel): EmailViewModel => {
  const { emails } = data;
  if (emails && emails.length > 0) {
    const pri = emails.find((it) => it.isPrimary);
    if (pri) {
      return pri;
    }
    return emails[0];
  }
  return {} as EmailViewModel;
};

export const getIdentificationObj = (data: ContactViewModel): IdentificationViewModel => {
  const { identifications } = data;
  if (identifications && identifications.length > 0) {
    const pri = identifications.find((it) => it.isPrimary);
    if (pri) {
      return pri;
    }
    return identifications[0];
  }
  return {} as IdentificationViewModel;
};

export const getIdentification = (data: ContactViewModel): string => {
  const { identifications } = data;
  if (identifications && identifications.length > 0) {
    const pri = identifications.find((it) => it.isPrimary);
    if (pri) {
      return pri.value;
    }
    return identifications[0].value;
  }
  return '';
};

export const getAddress = (data: ContactViewModel): AddressViewModel | {} => {
  const { addresses } = data;
  if (addresses && addresses.length > 1) {
    const pri = addresses.find((it) => it.isPrimary);
    if (pri) {
      return pri;
    }
    return addresses[0];
  }
  return {};
};
