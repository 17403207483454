export const contactCategories = ['Person', 'Company'];
export const idCategories = ['NIN', 'TIN', 'FCN', 'NSSF', 'Passport', 'DrivingPermit', 'VillageCard', 'Other'];
export const genderCategories = ['Male', 'Female'];
export const salutationCategories = ['Mr', 'Mrs', 'Ms', 'Dr', 'Prof'];
export const companyCategories = ['Limited', 'NGO', 'Other'];
export const civilStatusCategories = ['Single', 'Married', 'Divorced', 'Other'];
export const emailCategories = ['Personal', 'Work', 'Other'];
export const phoneCategories = ['Mobile', 'Office', 'Home', 'Fax', 'Other'];
export const addressCategories = ['Home', 'Work', 'Other'];
export const hobbyCategories = ['FootBall', 'Work', 'Other'];
export const companyTypeCategories = ['Limited', 'NGO', 'Sole proprietorship', 'Other'];
export const filterTypes = ['Created At', 'Type'];
export const gender = ['Female', 'Male'];
export const nationalities = ['Ugandan'];
