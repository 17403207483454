import { FC } from 'react';
import { Button, } from '@mui/material';

interface ContactUsProps {
  title: string;
}

const ContactUsButton: FC<React.PropsWithChildren<ContactUsProps>> = (props) => {
  const handleClick = (): void => {
    window.location.href = 'mailto:support@iotec.io?subject=IOTEC LUMEN SUPPORT REQUEST';
  };

  return (
        <Button
            color="primary"
            sx={{
              justifyContent: 'flex-start',
              py: 2,
              textTransform: 'none',
              width: '100%'
            }}
            variant="text"
            onClick={handleClick}
        >
            {props.title}
        </Button>
  );
};

export default ContactUsButton;
