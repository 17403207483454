import React from 'react';
import { TextField } from '@mui/material';
import XTextInput from '../inputs/XTextInput';
import { IColumn, InputType } from './types';
import XDateInput from '../inputs/XDateInput';
import XRadioInput from '../inputs/XRadioInput';
import XSelectInput from '../inputs/XSelectInput';
import XTextAreaInput from '../inputs/XTextAreaInput';
import { XRemoteSelect } from '../inputs/XRemoteSelect';
import PDateInput from '../plain-inputs/PDateInput';
import PRadioInput from '../plain-inputs/PRadioInput';
import { PRemoteSelect } from '../plain-inputs/PRemoteSelect';
import PComboInput from '../plain-inputs/PComboInput';
import XCheckBoxInput from '../inputs/XCheckBoxInput';
import XSwitchInput from '../inputs/XSwitchInput';

export const renderInput = ({ name, label, inputProps: { inputType, ...extraProps } }: IColumn) => {
  if (inputType) {
    const type: InputType = inputType;
    const inputProperties:any = { name, label, ...extraProps, margin: 'none' };
    switch (type) {
      case InputType.Text:
        return (
          <XTextInput
            {...inputProperties}
          />
        );
      case InputType.Date:
        return (
          <XDateInput
            {...inputProperties}
          />
        );
      case InputType.Radio:
        return (
          <XRadioInput
            {...inputProperties}
          />
        );
      case InputType.CheckBox:
        return (
          <XCheckBoxInput
            {...inputProperties}
          />
        );
      case InputType.Switch:
        return (
          <XSwitchInput
            {...inputProperties}
          />
        );
      case InputType.TextArea:
        return (
          <XTextAreaInput
            {...inputProperties}
          />
        );
      case InputType.Select:
        return (
          <XSelectInput
            {...inputProperties}
          />
        );
      case InputType.RemoteSelect:
        return (
          <XRemoteSelect
            {...inputProperties}
          />
        );
      default:
        return (
          <XTextInput
            {...inputProperties}
          />
        );
    }
  } return undefined;
};
export type PlainInputProps = {
  definition:IColumn
  value:any
  onChange:any
};

export const PlainInput:React.FC<React.PropsWithChildren<PlainInputProps>> = ({
  definition: { name, label, inputProps: { inputType, gridProps, ...extraProps } },
  onChange, value
}) => {
  if (inputType) {
    const type: InputType = inputType;
    const inputProperties:any = { name, label, onChange, value, margin: 'none', size: 'small', ...extraProps };
    switch (type) {
      case InputType.Date:
        return (
          <PDateInput
            {...inputProperties}
          />
        );
      case InputType.Radio:
        return (
          <PRadioInput
            {...inputProperties}
          />
        );

      case InputType.Select:
        return (
          <PComboInput
            disableCloseOnSelect
            {...inputProperties}
          />
        );
      case InputType.RemoteSelect:
        return (
          <PRemoteSelect
            disableCloseOnSelect
            {...inputProperties}
          />
        );
      default:
        return (
          <TextField
            {...inputProperties}
          />
        );
    }
  } return undefined;
};
