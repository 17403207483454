import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { AppBarProps } from '@mui/material';
import { AppBar, Box, Hidden, IconButton, Toolbar, Typography } from '@mui/material';
import { experimentalStyled } from '@mui/material/styles';
import MenuIcon from '../../icons/Menu';
import AccountPopover from './AccountPopover';
import AppsPopover from './AppsPopover';
import { appSettings } from '../../config';

interface DashboardNavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => void;
}

const DashboardNavbarRoot = experimentalStyled(AppBar)(
  ({ theme }) => (
    {
      ...(
        theme.palette.mode === 'light' && {
          backgroundColor: theme.palette.primary.main,
          boxShadow: 'none',
          color: theme.palette.primary.contrastText
        }
      ),
      ...(
        theme.palette.mode === 'dark' && {
          backgroundColor: theme.palette.background.paper,
          borderBottom: `1px solid ${theme.palette.divider}`,
          boxShadow: 'none'
        }
      )
    }
  )
);

const DashboardNavbar: FC<React.PropsWithChildren<DashboardNavbarProps>> = (props) => {
  const { onSidebarMobileOpen, ...other } = props;

  return (
        <DashboardNavbarRoot {...other}>
            <Toolbar sx={{ minHeight: 64 }}>
                <Hidden lgUp>
                    <IconButton
                        color="inherit"
                        onClick={onSidebarMobileOpen}
                        size="large"
                    >
                        <MenuIcon fontSize="small" />
                    </IconButton>
                </Hidden>
                <Hidden lgDown>
                    <Box
                        sx={{
                          ml: 2
                        }}
                    >
                        <Typography
                            color="text.primary"
                            variant="h6"
                        >
                            {appSettings.appName}
                        </Typography>
                    </Box>
                </Hidden>
                <Box
                    sx={{
                      flexGrow: 1,
                      ml: 2
                    }}
                />
                <Box sx={{ ml: 2 }}>
                    <AppsPopover />
                </Box>
                <Box sx={{ ml: 2 }}>
                    <AccountPopover />
                </Box>
            </Toolbar>
        </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default DashboardNavbar;
