import { lazy, Suspense } from 'react';
import { RouteObject } from 'react-router';
import AuthGuard from 'components/AuthGuard';
import DashboardLayout from 'components/dashboard/DashboardLayout';
import GuestGuard from 'components/GuestGuard';
import LoadingScreen from 'components/LoadingScreen';
import MainLayout from 'components/MainLayout';
import CallBack from 'pages/authentication/login/Callback';

const Loadable = (Component: any) => (props: any) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Authentication pages
const Login = Loadable(lazy(() => import('pages/authentication/Login')));

// General pages
const CreditCheckRequests = Loadable(lazy(() => import('pages/products/credit-checks/CreditCheckRequests')));
const CreditCheckReport = Loadable(lazy(() => import('pages/products/credit-checks/CreditCheckReport')));

const DataSubmissions = Loadable(lazy(() => import('pages/products/data-submissions/DataSubmissions')));
const DataSubmissionUploader = Loadable(lazy(() => import('pages/products/data-submissions/DataSubmissionUploader')));
const DataSubmissionDetails = Loadable(lazy(() => import('pages/products/data-submissions/DataSubmissionDetails')));

// Admin pages
const PartnersList = Loadable(lazy(() => import('pages/management/partners/Partners')));
const StaffList = Loadable(lazy(() => import('pages/management/staff/Staff')));
const StaffDetails = Loadable(lazy(() => import('pages/management/staff/details/StaffDetails')));
const Settings = Loadable(lazy(() => import('pages/management/settings/Settings')));
const Invoices = Loadable(lazy(() => import('pages/management/invoices/Invoices')));
const PriceBandsList = Loadable(lazy(() => import('pages/management/price-bands/PriceBands')));


// Account pages
const Profile = Loadable(lazy(() => import('pages/account/Profile')));

// Error pages
const AuthorizationRequired = Loadable(lazy(() => import('pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('pages/NotFound')));
const ServerError = Loadable(lazy(() => import('pages/ServerError')));

const routes: RouteObject[] = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'login-unguarded',
        element: <Login />
      },
      {
        path: 'login-callback',
        element: <CallBack />
      }
    ]
  },
  {
    path: '*',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <CreditCheckRequests />
      },
      {
        path: 'credit-checks',
        children: [
          {
            path: '',
            element: <CreditCheckRequests />
          },
          {
            path: ':reportId',
            element: <CreditCheckReport />
          }
        ]
      },
      {
        path: 'data-submissions',
        children: [
          {
            path: '',
            element: <DataSubmissions />
          },
          {
            path: 'upload',
            element: <DataSubmissionUploader />
          },
          {
            path: ':bulkId',
            element: <DataSubmissionDetails />
          }
        ]
      },
      {
        path: 'staff',
        children: [
          {
            path: '',
            element: <StaffList />
          },
          {
            path: ':staffId',
            element: <StaffDetails />
          }
        ]
      },
      {
        path: 'partners/:partnerId',
        element: <Settings />
      },
      {
        path: 'partners',
        element: <PartnersList />
      },
      {
        path: 'settings',
        children: [
          {
            path: '',
            element: <Settings />
          },
          {
            path: ':partnerId',
            element: <Settings />
          }
        ]
      },
      {
        path: 'invoices',
        element: <Invoices />
      },
      {
        path: 'price-bands',
        element: <PriceBandsList />
      },
      {
        path: 'profile',
        children: [
          {
            path: '',
            element: <Profile />
          }
        ]
      }
    ]
  },
  {
    path: 'error',
    element: <MainLayout />,
    children: [
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export default routes;
