import type { FC } from 'react';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Avatar, Box, Button, ButtonBase, Divider, Popover, Typography } from '@mui/material';
import useAuth from '../../hooks/useAuth';
import getInitials from '../../utils/getInitials';
import AccountIcon from '@mui/icons-material/AccountCircleOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import { toTitleCase } from '../../utils/stringHelpers';
import { useTheme } from '@mui/material/styles';

const AccountPopover: FC<React.PropsWithChildren<unknown>> = () => {
  const theme = useTheme();
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleEditProfile =  () => {
    handleClose();
    navigate('/profile');
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout();
      navigate('/login');
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'error'
      });
    }
  };

  return (
        <>
            <Box
                component={ButtonBase}
                onClick={handleOpen}
                ref={anchorRef}
                sx={{
                  alignItems: 'center',
                  display: 'flex'
                }}
            >
                <Avatar
                    sx={{
                      width: 36,
                      height: 36
                    }}
                >
                    {getInitials(user.name)}
                </Avatar>
            </Box>
            <Popover
                anchorEl={anchorRef.current}
                anchorOrigin={{
                  horizontal: 'center',
                  vertical: 'bottom'
                }}
                keepMounted
                onClose={handleClose}
                open={open}
                PaperProps={{
                  sx: {
                    width: 300,
                    mt: 2,
                    boxShadow: theme.shadows[10],
                  }
                }}
            >
                <Box sx={{ p: 2 }}>
                    <Typography
                        color="textPrimary"
                        variant="subtitle2"
                    >
                        {toTitleCase(user.name)}
                    </Typography>
                    <Typography
                        color="textSecondary"
                        variant="caption"
                    >
                        {user.email}
                    </Typography>
                </Box>
                <Divider sx={{ mx: 2 }}/>
                <Box sx={{ p: 2 }}>
                    <Button
                        color="inherit"
                        variant="text"
                        sx={{
                          justifyContent: 'flex-start',
                          color: 'text.secondary',
                          width: '100%'
                        }}
                        startIcon={<AccountIcon/>}
                        onClick={handleEditProfile}
                    >
                        Edit profile
                    </Button>
                </Box>
                <Box sx={{ p: 2 }}>
                    <Button
                        color="inherit"
                        variant="text"
                        sx={{
                          justifyContent: 'flex-start',
                          color: 'text.secondary',
                          width: '100%'
                        }}
                        startIcon={<LogoutIcon/>}
                        onClick={handleLogout}
                    >
                        Sign out
                    </Button>
                </Box>
            </Popover>
        </>
  );
};

export default AccountPopover;
